import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/buttons/button";

import aboutHero from "../images/about/about-hero.png";
import lady from "../images/about/lady.png";
import hand from "../images/about/hand.png";
import Hero from "../images/about/about-hero.jpg";
import banner from "../images/about/about-end-banner.jpg";
import facebookIcon from "../icons/facebook.png";
import linkedinIcon from "../icons/linked-in.png";
import instagramIcon from "../icons/instagram.png";
import twitterIcon from "../icons/twitter.png";
import tiktokIcon from "../icons/tik-tok.png";
import {Link} from "gatsby";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Nubee`,
          `driving school`,
          `learners`,
          `artificial intelligence`,
        ]}
        title="About"
      />

      <section
        className="flex justify-center z-30 m-5 p-8 py-24 mt-20 md:mt-6 md:py-32 lg:py-40 bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${Hero})` }}
      >
        <div className="lg:w-3/6">
          <article className="flex flex-col justify-center items-center text-white">
            <h3 className="mt-5 font-heading text-center text-3xl lg:text-6xl">
              A Platform enabling access to mobility for young people
            </h3>
            <p className="w-4/5 mt-5 text-center text-md">
              You can now get your drivers licence all through a safe online
              platform of certified driving instructors
            </p>
          </article>
          <div className="text-center">
            <Button
              title="Let's Get Started"
              href="https://app.nubee.co.za/#/"
            ></Button>

            {/*
                <li className="hover:text-nubee-purple"><Link to="https://app.nubee.co.za/#/auth/login">Let's Get Started</Link></li>
*/}

            {/*              <Button title="Let's Get Started"></Button>*/}
          </div>
        </div>
      </section>
      <div className="border-b-2 border-nubee-blue mt-24 w-32 mx-auto"></div>

      <section className="md:p-0 text-left mt-16 md:mt-24">
        <div className="flex flex-col gap-32 md:flex-row px-6 md:max-w-7xl mx-auto">
          <img
            alt="driving school illustration"
            src={lady}
            className="object-contain w-3/4 md:w-2/5 mx-auto"
          />
          <div className="md:pr-2">
            <div>
              <h1 className="inline-block text-2xl text-black font-heading">
                Why choose us?
              </h1>
            </div>
            <div>
              <p className="py-8 text-black font-heading md:text-6xl">
                Convenient, safe and radically cheaper
              </p>
              <p className="pb-8 text-black font-body">
                We are disrupting the traditional driving school sector by
                making the process of learning to drive a whole lot more{" "}
                <b>convinient, affordable and fun. </b> We are doing this by
                using technology to simplify the process of booking your test
                date and finding your perfect instructor.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="md:p-0 text-left mt-16 md:mt-24">
        <div className="flex flex-col gap-32 md:flex-row px-6 md:max-w-7xl mx-auto">
          <div className="md:pr-2">
            <div>
              <h1 className="inline-block text-2xl text-black font-heading">
                Our Mission
              </h1>
            </div>
            <div>
              <p className="py-8 text-black font-heading md:text-6xl">
                Promote mobility and autonomy by making learning to drive an
                easy experience for everyone
              </p>
              <p className="pb-8 text-black font-body">
                We believe that being able to drive is one of the most crucial
                skills in life. It enables autonomy and the ability to make
                money as a mobility entrepreneur.
              </p>
              {/* <p className="pb-4 text-black font-body">Email us at:<span className="text-nubee-pink"> support@nubee.co.za</span></p> */}
              {/* <p className="text-black font-body">Phone us on:<span className="text-nubee-pink"> 021 768 9876; +27 987 7654</span></p> */}
              {/* <h2 className="font-bold pt-8">Follow us on all of our platforms to stay connected.</h2>
              <div className="flex mx-auto gap-5 mt-5 justify-start">
                <img className="w-8" src={facebookIcon}/>
                <img className="w-8" src={linkedinIcon}/>
                <img className="w-8" src={instagramIcon}/>
                <img className="w-8" src={twitterIcon}/>
                <img className="w-8" src={tiktokIcon}/>
              </div> */}
            </div>
          </div>
          <img
            alt="driving school illustration"
            src={aboutHero}
            className="object-contain w-3/4 md:w-2/5 mx-auto"
          />
        </div>
      </section>

      <div className="border-b-2 border-nubee-blue mt-24 w-32 mx-auto"></div>

      <section className="md:p-0 text-left mt-16 md:mt-24">
        <div className="flex flex-col gap-32 md:flex-row px-6 md:max-w-7xl mx-auto">
          <div className="md:pr-2">
            <div>
              <h1 className="inline-block text-2xl text-black font-heading">
                Our Vision
              </h1>
            </div>
            <div>
              <p className="py-8 text-black font-heading md:text-6xl">
                To become the leading driving education platform for mobility
                entrepreneurs
              </p>
              <p className="pb-8 text-black font-body">
                Whether you are wanting to learn to drive for ultimate freedom
                or are wanting to earn money driving, Nubee will be the
                all-in-one solution to help you get there.
              </p>
            </div>
          </div>
          <img
            alt="driving school illustration"
            src={hand}
            className="object-contain w-3/4 md:w-2/5 mx-auto"
          />
        </div>
      </section>

      <div className="border-b-2 border-nubee-blue mt-24 w-32 mx-auto"></div>

      <section
        className="flex justify-center z-30 m-5 p-8 py-24 mt-20 md:mt-16 md:py-32 lg:py-40 bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="lg:w-3/6">
          <article className="flex flex-col justify-center items-center text-white">
            <h3 className="mt-5 font-heading text-center text-3xl lg:text-6xl">
              Learn to drive at your own pace with Nubee
            </h3>
          </article>
          <div className="text-center">
            {/* <li className="hover:text-nubee-purple">
              <Link to="https://app.nubee.co.za/#/auth/login">Join Now</Link>
            </li> */}
            <Button title="Join Now" href="https://app.nubee.co.za"></Button>
          </div>
        </div>
      </section>

      <section className="px-6 md:px-28 mt-10 md:mt-24 py-10 md:py-16 md:px-36 text-center">
        {/*        <p className="text-black text-center md:w-2/4 mx-auto pt-5">Subscribe to our driving community and get the latest Nubee news and updates.</p>
        <form className="flex flex-col md:flex-row justify-center items-center mx-auto">
          <div className="pt-5 w-full md:w-1/3">
            <input
              className="w-full rounded-md border-gray-800 h-12 text-center md:text-left"
              id="first-name"
              placeholder="Email Address"
              type="email"
            />
          </div>
          <Button title="Sign up"></Button>
        </form>*/}
        <h2 className="font-bold pt-14">
          Follow us on all of our platforms to stay connected.
        </h2>
        <div className="flex mx-auto gap-5 mt-5 justify-center">
          <img className="w-8" src={facebookIcon} />
          <img className="w-8" src={linkedinIcon} />
          <img className="w-8" src={instagramIcon} />
          <img className="w-8" src={twitterIcon} />
          <img className="w-8" src={tiktokIcon} />
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage;
